<template>
  <gmap-polyline :path="myPath" :options="options" />
</template>

<script>
import { $decodePath } from '../helper';

export default {
  computed: {
    arrowIcons() {
      if (!this.showDirection) return {};

      return {
        icons: [
          {
            icon: {
              // eslint-disable-next-line no-undef
              path: google.maps.SymbolPath.FORWARD_OPEN_ARROW,
              scale: 2,
              strokeColor: this.color
            },
            offset: '0',
            repeat: '150px'
          }
        ]
      };
    },
    options() {
      return {
        strokeColor: this.color,
        strokeWeight: this.weight,
        ...this.arrowIcons
      };
    },
    myPath() {
      if (this.encodedPath) return $decodePath(this.encodedPath);
      if (this.path) return this.path;
      return [];
    }
  },
  props: {
    color: { type: String, default: '#026C75' },
    showDirection: { type: Boolean, default: false },
    encodedPath: { type: String, default: '' },
    path: { type: Array, default: () => [] },
    weight: { type: [String, Number], default: '3' }
  }
};
</script>
